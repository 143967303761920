<template>
  <div class="desc-container" id="desc">
    <div class="container">
      <!--div class="desc-title center-title">
        <h3>简介</h3>
      </div-->
      <div class="desc-box">
        <p>
          未来科技研究所(Future Technology
          Laboratory)成立于2016年，以科技创造未来为宗旨，是一个由科技爱好者组成的团队。主要研究领域为计算机、互联网、软件、机械、电气等。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.desc-container {
  min-height: 100px;
  margin: 20px 0;
  /*margin-top: 100px;*/
}

.desc-box {
  background-color: bisque;
  margin: 0 auto;
  padding: 10px;
}

.desc-box p {
  font-size: 20px;
  line-height: 40px;
  text-indent: 30px;
  font-style: italic;
  font-family: "ZCOOL XiaoWei", serif;
  font-weight: bold;
}
</style>