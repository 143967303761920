<template>
  <div class="cover-container" ref="coverBox">
    <div class="cover-front">
      <div class="cover-title">
        <div class="main-title">
          <h1>未来科技研究所</h1>
        </div>
        <div class="sec-title">
          <h2>Future Technology Laboratory</h2>
        </div>
        <div class="next-page-button" @click="scollLeaveCover">
          <img src="@/assets/mouse.png" alt="" />
          <p>向下翻页</p>
        </div>
      </div>
    </div>
    <div class="cover-background" id="coverback">
      <Particles id="tsparticles" :options="particlesSetting" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      particlesSetting: {
        background: {
          color: {
            value: "#000000",
          },
        },
        fullScreen: {
          enable: false,
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        detectRetina: true,
      },
      coverPosition: 0,
    };
  },
  components: {},

  mounted() {
    this.freshCoverPosition();
    //window.addEventListener("mousewheel", this.handleScroll, true);
  },
  watch: {},
  methods: {
    freshCoverPosition() {
      this.coverPosition = this.$refs.coverBox.getBoundingClientRect().y;
      //console.log("pos", this.coverPosition);
      return this.coverPosition;
    },
    handleScroll(data) {
      this.freshCoverPosition();
      if (this.coverPosition === 0 && data.deltaY > 0) {
        //console.log("首页下滑");
        this.scollLeaveCover();
      }
    },
    scollLeaveCover() {
      window.scrollTo({
        top: this.$refs.coverBox.offsetHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.cover-container {
  position: relative;
  height: 100%;
}

.cover-front {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  color: #ffffff;
  user-select: none;
}

.cover-background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.cover-title {
  width: 100%;
  margin: 0 auto;
  margin-top: 20%;
}

.main-title h1 {
  text-align: center;
  color: #ffffff;
  font-size: 80px;
  letter-spacing: 20px;
  font-family: "ZCOOL QingKe HuangYou", cursive;
  line-height: 100px;
}

.sec-title h2 {
  text-align: center;
  color: #ffffff;
  font-size: 40px;
  letter-spacing: 3px;
  font-family: "Inconsolata", monospace;
  line-height: 80px;
}

.next-page-button {
  width: 100%;
  height: 40px;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.next-page-button img {
  width: 30px;
}
.next-page-button p {
  font-size: 10px;
  line-height: 14px;
}
</style>