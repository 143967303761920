<template>
  <div class="news-container">
    <div class="container">
      <div class="news-box">
        <el-row>
          <el-col :span="12">
            <div class="news-carousel news-part">
              <el-carousel trigger="click" height="360px" ref="carousel">
                <el-carousel-item v-for="item in 4" :key="item">
                  <h3 class="small">{{ item }}</h3>
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="news-list news-part">bb</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.news-box {
  height: 360px;
}

.news-part {
  height: 360px;
}

.news-carousel {
  background-color: bisque;
}

.news-list {
  background-color: blueviolet;
}
</style>