<template>
  <div class="main-container">
    <div style="height: 100%">
      <cover-block />
    </div>
    <!--div>
      <desc-block />
    </div>
    <div>
      <news-block />
    </div>
    <div>
      <footer-block />
    </div-->
  </div>
</template>

<script>
import CoverBlock from "../components/CoverBlock.vue";
import DescBlock from "../components/DescBlock.vue";
import FooterBlock from "../components/FooterBlock.vue";
import NewsBlock from "../components/NewsBlock.vue";

export default {
  components: { CoverBlock, DescBlock, FooterBlock, NewsBlock },
};
</script>

<style>
.main-container {
  height: 100%;
}
</style>