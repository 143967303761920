<template>
<div class="footer-container">

</div>
</template>

<script>
export default {

}
</script>

<style>
.footer-container{
    background-color: #000000;
    height: 200px;
}
</style>